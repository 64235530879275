module directives {
    export module costing {
        interface ICostingDirectiveScope extends ng.IScope {
        }

        export class costingDirective implements ng.IDirective {
            templateUrl = 'templates/modules/applicationmain/costing/costingDirectiveView.html';
            scope = {
                entityId: "=",
                costModelType: "=",
                ngModel: "=",
                loadViewDetails: "&",
                recost: "&",
                disId: "=",
                ngDisabled: "=?"
            };
            controller = "costingCtrl";
            controllerAs = "costingCtrl";

            constructor(public $q: ng.IQService) {
            }

            link = ($scope: ng.IScope, $element: ng.IAugmentedJQuery) => {

            }

            static factory(): ng.IDirectiveFactory {
                const directive = ($q) => new costingDirective($q);
                directive.$inject = ['$q'];

                return directive;
            }
        }

        angular.module("app").directive("gtsCosting", costingDirective.factory());
    }
}